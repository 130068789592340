.yotitleNotification {
  background: #eceef3;
  width: 100%;
  max-height: 96px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  transition: max-height .3s ease-out;
  display: none;
  overflow: hidden;
}

.yotitleNotification .yotitleWrapper {
  justify-content: space-between;
  padding: 12px 12px 12px 24px;
  display: flex;
}

.yotitleNotification .description {
  color: #232735;
}

.yotitleNotification .description .labelIcon {
  float: left;
  width: 19px;
  min-width: 19px;
  height: 24px;
  min-height: 24px;
  margin-right: 8px;
}

.yotitleNotification .description .link {
  font-family: CirceBold;
  font-weight: initial;
  -webkit-font-smoothing: antialiased;
  color: #536ced;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
}

.yotitleNotification.shown {
  display: block;
}

.closeIcon {
  cursor: pointer;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.closeIcon svg {
  fill: #acb2c3;
}
/*# sourceMappingURL=yotitle.9154fa12.css.map */
