@use "../../public/styles/variables.scss";

.yotitleNotification {
  @include variables.P4;
  background: variables.$ui_02;
  width: 100%;
  overflow: hidden;
  max-height: 96px;
  transition: max-height 300ms ease-out;
  display: none;
  
  .yotitleWrapper {
    padding: 12px 12px 12px 24px;
    display: flex;
    justify-content: space-between;
  }

  .description {
    color: variables.$text_01;

    .labelIcon {
      margin-right: 8px;
      width: 19px;
      height: 24px;
      min-width: 19px;
      min-height: 24px;
      float: left;
    }

    .link {
      @include variables.P4Bold;
      color: variables.$link;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.yotitleNotification.shown {
  display: block;
}

.closeIcon {
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  cursor: pointer;

  svg {
    fill: variables.$ui_04;
  }
}